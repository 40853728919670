import {
    RGBAFormat,
    LinearFilter,
    LinearSRGBColorSpace,
    NearestFilter
} from "three"

function setTexture(_texture) {
    if (!_texture) return null
    _texture.format = RGBAFormat
    _texture.generateMipmaps = false
    _texture.colorSpace = LinearSRGBColorSpace
    _texture.minFilter = LinearFilter
    _texture.magFilter = NearestFilter
    _texture.anisotropy = 1
    return _texture
}

window.UTILS = {
    setTexture: setTexture
}