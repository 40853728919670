import { Clock } from 'three';
import mitt from 'mitt';

window.RAF = mitt();
const clock = new Clock();

const targetFPS = 24;
const targetInterval = 1000 / targetFPS;
let lastTime = 0;

function animate(time) {
    requestAnimationFrame(animate);
    
    const delta = clock.getDelta();
    // const elapsed = time - lastTime;

    // if (elapsed > targetInterval) {
    //     lastTime = time - (elapsed % targetInterval);
    //     window.RAF.emit('animate', delta);
    // }
    window.RAF.emit('animate', delta)
}

animate(0);